// extracted by mini-css-extract-plugin
export var alignLeft = "r_qh d_fp d_bG d_dv";
export var alignCenter = "r_bP d_fq d_bD d_dw";
export var alignRight = "r_qj d_fr d_bH d_dx";
export var testimonialsContainer = "r_rc d_dW";
export var testimonialsContainerFull = "r_rd d_dT";
export var testimonialsMainHeader = "r_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "r_jy d_jy";
export var testimonialsComponentSmall = "r_jx d_jx";
export var testimonialsComponentsWrapper = "r_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "r_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "r_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "r_rf d_d7";
export var colEqualHeight = "r_rg d_bz";
export var testimonialsColumnWrapper = "r_jz d_jz d_b3";
export var testimonialsImageWrapper = "r_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "r_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "r_jr d_jr d_Z d_bz";
export var design2TextWrapper = "r_rh d_bC d_bP d_bJ";
export var design3 = "r_rj d_cD d_H";
export var imageDesign2 = "r_rk";
export var SmallSmall = "r_rl t_rl t_rq t_rN";
export var SmallNormal = "r_rm t_rm t_rq t_rP";
export var SmallLarge = "r_rn t_rn t_rq t_rL";
export var exceptionWeight = "r_qL t_rS";