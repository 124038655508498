// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_qV d_gv d_cs";
export var heroHeaderCenter = "q_gw d_gw d_cs d_dw";
export var heroHeaderRight = "q_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "q_qW d_gr d_cw";
export var heroParagraphCenter = "q_gs d_gs d_cw d_dw";
export var heroParagraphRight = "q_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "q_qX d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "q_qY d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "q_qZ d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "q_q0 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "q_q1 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "q_q2 t_q2";
export var heroExceptionNormal = "q_q3 t_q3";
export var heroExceptionLarge = "q_q4 t_q4";
export var Title1Small = "q_q5 t_q5 t_rq t_rr";
export var Title1Normal = "q_q6 t_q6 t_rq t_rs";
export var Title1Large = "q_q7 t_q7 t_rq t_rt";
export var BodySmall = "q_q8 t_q8 t_rq t_rK";
export var BodyNormal = "q_q9 t_q9 t_rq t_rL";
export var BodyLarge = "q_rb t_rb t_rq t_rM";